import Button from '@mui/material/Button'
import { logout } from '../../api'
import { CourseProps } from '../../components/course/Course'
import styles from './HomePage.module.scss'
import sumsub from './sumsub.png'

function HomePage() {
  const handleLogout = () => {
    logout()
  }

  return (
    <>
      <div className={styles.home}>
        <header className={styles.header}>
          <div className={styles.profile}>
            <Button variant='outlined' className={styles.logout} onClick={handleLogout}>
              Cancel
            </Button>
          </div>
        </header>
        <div className={styles.container}>
          <img src={sumsub} className={styles.content} alt='sumsub' />
        </div>
      </div>
    </>
  )
}

export default HomePage

export const courses: CourseProps[] = [
  {
    title: 'Cat parent',
    description: 'Learn to take care of a rescue cat',
    imageUrl: 'cat.jpg',
    tags: ['animals', 'cats'],
  },
  {
    title: 'Blues guitar',
    description: `Delta blues beginner's course`,
    imageUrl: 'colours.jpg',
    tags: ['music', 'guitar'],
  },
  {
    title: 'Camp like a pro',
    description: 'Essential skills in the wild',
    imageUrl: 'fire.jpg',
    tags: ['nature', 'camping'],
  },
  {
    title: 'Hiking',
    description: 'Finding the best mountainous trails',
    imageUrl: 'mountains.jpg',
    tags: ['nature', 'camping'],
  },
  {
    title: 'Foraging',
    description: 'How to tell edible mushrooms from poisonous',
    imageUrl: 'mushroom.jpg',
    tags: ['nature', 'food'],
  },
  {
    title: 'Gardening',
    description: 'Growing amazing vegetables in your garden',
    imageUrl: 'pumpkin.jpg',
    tags: ['nature', 'garden', 'plants'],
  },
  {
    title: 'Stone skipping',
    description: 'Cool skill for meditation and party tricks',
    imageUrl: 'stones.jpg',
    tags: ['nature', 'meditation'],
  },
  {
    title: 'Bird watching',
    description: 'Discover amazing birds in your area',
    imageUrl: 'bird.jpg',
    tags: ['nature', 'birds'],
  },
  {
    title: 'Succulents',
    description: 'Top 10 unpretentious plants for your home',
    imageUrl: 'succulents.jpg',
    tags: ['nature', 'plants'],
  },
  {
    title: 'Making pasta',
    description: 'Learn to make unforgettable home pasta',
    imageUrl: 'pasta.jpg',
    tags: ['cooking', 'food'],
  },
  {
    title: 'Frontend development',
    description: 'React.js - the most popular Javascript framework',
    imageUrl: 'code.jpg',
    tags: ['coding', 'code', 'software', 'engineering'],
  },
  {
    title: 'Jazz guitar',
    description: 'Jazz guitar fundamentals',
    imageUrl: 'guitar.jpg',
    tags: ['guitar', 'music'],
  },
  {
    title: 'Woodworking',
    description: 'Create beautiful handcrafted furniture',
    imageUrl: 'woodworking.jpg',
    tags: ['diy', 'woodworking'],
  },
  {
    title: 'Housework',
    description: `You'll never need a pro for simple home repairs`,
    imageUrl: 'housework.jpg',
    tags: ['diy', 'repairs'],
  },
  {
    title: 'Leatherworking 101',
    description: 'Learn leather crafting',
    imageUrl: 'leatherwork.jpg',
    tags: ['diy', 'leather'],
  },
]
