import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { FormControlLabel, NativeSelect, Radio, RadioGroup, TextField } from '@mui/material'
import { FormEvent, useState } from 'react'
import * as api from '../../api'
import { GenericError } from '../../api'
import { Layout } from '../../components/Layout'
import { useHomepageRedirect } from '../../components/redirects'

function SignUpPage() {
  useHomepageRedirect()
  const [name, setName] = useState('John Doe')
  const [password, setPassword] = useState('1234')
  const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.ID)
  const [country, setCountry] = useState<Countries>(Countries.DE)
  const [email, setEmail] = useState(`Exness_${country}_${documentType}`)
  const [error, setError] = useState<GenericError | undefined>(undefined)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    getData({ ignoreCache: true }).then((data) => {
      if (data) {
        console.log(data)
        api.signup({ name, email, password, visitorId: data.visitorId, requestId: data.requestId }).catch((e) => {
          setError(e)
        })
      }
    })
  }

  const { getData } = useVisitorData({ linkedId: email }, { immediate: false })

  return (
    <Layout>
      <section className='body'>
        <div className='auth-form container'>
          <div className='row auth-form-content'>
            <div className='card'>
              <div className='card-body'>
                <h1 className='card-title'>Verify your identity</h1>
                <p className='promo-text'>Follow the instructions below. It will only take about 2 minutes.</p>
                <form className='form' onSubmit={handleSubmit}>
                  <div className='form-control hidden'>
                    <label htmlFor='name'>Name:</label>
                    <TextField
                      id='name'
                      type='text'
                      name='name'
                      variant='outlined'
                      required
                      value={name}
                      onChange={(e) => setName(e.currentTarget.value)}
                    />
                  </div>
                  <div className='form-control hidden'>
                    <label htmlFor='email'>Email:</label>
                    <TextField
                      id='email'
                      type='text'
                      name='email'
                      variant='outlined'
                      required
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </div>
                  <div className='form-control hidden'>
                    <label htmlFor='password'>Password:</label>
                    <TextField
                      id='password'
                      type='password'
                      name='password'
                      required
                      variant='outlined'
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                  </div>

                  <div className='form-control'>
                    <label htmlFor='uncontrolled-native'>Select the country that issued your document:</label>
                    <NativeSelect
                      inputProps={{
                        name: 'country',
                        id: 'uncontrolled-native',
                      }}
                      value={country}
                      onChange={(e) => {
                        setCountry(e.currentTarget.value as Countries)
                        setEmail(`Exness_${e.currentTarget.value as Countries}_${documentType}`)
                      }}
                    >
                      <option value={Countries.DE}>{Countries.DE}</option>
                      <option value={Countries.UK}>{Countries.UK}</option>
                      <option value={Countries.USA}>{Countries.USA}</option>
                      <option value={Countries.FR}>{Countries.FR}</option>
                    </NativeSelect>
                  </div>
                  <div className='form-control'>
                    <label htmlFor='document-type'>Document type:</label>
                    <RadioGroup
                      name='document-type'
                      value={documentType}
                      onChange={(e) => {
                        setDocumentType(e.currentTarget.value as DocumentType)
                        setEmail(`Exness_${country}_${e.currentTarget.value as DocumentType}`)
                      }}
                    >
                      <FormControlLabel value={DocumentType.ID} control={<Radio />} label='ID' />
                      <FormControlLabel
                        value={DocumentType.DRIVER_LICENSE}
                        control={<Radio />}
                        label="Driver's license"
                      />
                      <FormControlLabel value={DocumentType.PASSPORT} control={<Radio />} label='Passport' />
                    </RadioGroup>
                  </div>
                  <SignupError error={error} />
                  <div className='auth-button-wrapper text-center'>
                    <button className='btn btn-primary auth-button' type='submit'>
                      Start verification
                    </button>
                  </div>
                </form>
                <div className='conditions'>
                  By signing up, you agree to our <a href='#'>Terms and Conditions</a> and the{' '}
                  <a href='#'>Privacy Policy</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

function SignupError({ error }: { error?: GenericError }) {
  if (!error) {
    return null
  }

  if (error.code === 'limit_restriction' && error.param === 'visitorId') {
    return (
      <p className='error'>
        Looks like you have had too many verification attempts. Please try again later.
        <br />
        If you don&apos;t think that is the case, please reach out to us at <b>support@sumsub.com</b>.
      </p>
    )
  }

  if (error.code === 'visitor_id_flagged' && error.param === 'visitorId') {
    return (
      <p className='error'>
        The device you are using has been involved in suspicious activity, so verification is temporary disabled.
        <br />
        If you think we got it wrong, please reach out to us at <b>support@sumsub.com</b>.
      </p>
    )
  }

  if (error.code === 'bot_detected' && error.param === 'requestId') {
    return (
      <p className='error'>
        The device you are using looks like an automated tool, so verification is currently not available.
        <br />
        If you think we got it wrong, please reach out to us at <b>support@sumsub.com</b>.
      </p>
    )
  }

  if (error.code === 'vpn_detected' && error.param === 'requestId') {
    return (
      <p className='error'>
        Looks like you are connected to a VPN. In order to proceed with the verification, please disconnect from it and
        try again.
        <br />
        If you think we got it wrong, please reach out to us at <b>support@sumsub.com</b>.
      </p>
    )
  }

  if (error.code === 'tampering_detected' && error.param === 'requestId') {
    return (
      <p className='error'>
        The device you are using looks like it has been tampered with, so verification is temporary disabled. Try
        disabling browser extensions that modify your browser.
        <br />
        If you think we got it wrong, please reach out to us at <b>support@sumsub.com</b>.
      </p>
    )
  }

  return <p className='error'>Invalid parameters</p>
}

export enum DocumentType {
  ID = 'id',
  DRIVER_LICENSE = 'driver_license',
  PASSPORT = 'passport',
}

export enum Countries {
  DE = 'Germany',
  UK = 'United Kingdom',
  USA = 'United States',
  FR = 'France',
}

export default SignUpPage
