import {
  defaultEndpoint as fpDefaultEndpoint,
  defaultScriptUrlPattern as fpDefaultScriptUrlPattern,
} from '@fingerprintjs/fingerprintjs-pro'
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react'
import { LoadOptions } from '@fingerprintjs/fingerprintjs-pro-spa'
import { StyledEngineProvider } from '@mui/material/styles'
import { useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import './App.css'
import { PrivateRoutes } from './components/redirects'
import { AppConfigProvider } from './context/config'
import AdminPage from './pages/AdminPage/AdminPage'

import HomePage from './pages/HomePage/HomePage'
import LogInPage from './pages/LogInPage/LogInPage'
import SignUpPage from './pages/SignUpPage/SignUpPage'
import { FPJS_API_KEY } from './utils/env'

function App() {
  const [loadOptions] = useState<LoadOptions>({
    apiKey: FPJS_API_KEY,
    scriptUrlPattern: [
      `https://fp.tryfingerprint.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
      fpDefaultScriptUrlPattern,
    ],
    endpoint: [`https://fp.tryfingerprint.com`, fpDefaultEndpoint],
  })

  return (
    <>
      <FpjsProvider loadOptions={loadOptions}>
        <AppConfigProvider>
          <StyledEngineProvider injectFirst>
            <BrowserRouter>
              <Routes>
                <Route element={<PrivateRoutes />}>
                  <Route path='/' element={<HomePage />} />
                  <Route path='/admin' element={<AdminPage />} />
                </Route>
                <Route path='verification' element={<SignUpPage />} />
                <Route path='login' element={<LogInPage />} />
                <Route path='*' element={<Navigate to='/' replace />} />
              </Routes>
            </BrowserRouter>
          </StyledEngineProvider>
        </AppConfigProvider>
      </FpjsProvider>
    </>
  )
}

export default App
