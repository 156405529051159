import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { getAuthorization } from '../api'

export function useUnauthorizedRedirect() {
  const token = getAuthorization()

  if (!token || token.length === 0) {
    return '/verification'
  }

  return null
}

export function useHomepageRedirect() {
  const navigate = useNavigate()
  const token = getAuthorization()

  if (token && token.length > 0) {
    navigate('/')
  }
}

export function PrivateRoutes() {
  const redirect = useUnauthorizedRedirect()

  if (redirect) {
    return <Navigate to={redirect} replace />
  }

  return <Outlet />
}
